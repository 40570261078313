export const keys = {
  CHECKOUT_URL: 'checkout::url',
  CHECKOUT_FR_URL: 'checkout_fr::url',
  CHECKOUT_US_URL: 'checkout_us::url',
  CHECKOUT_DE_URL: 'checkout_de::url',
  CHECKOUT_UK_URL: 'checkout_uk::url',
  CHECKOUT_BE_URL: 'checkout_be::url',
  CHECKOUT_ES_URL: 'checkout_es::url',
  FREE_TRAVEL_SIZE_POPUP: 'IS_FREE_TRAVEL_SIZE_POPUP_CLOSED',
  NO_KICKBACKFEE_POPUP: 'IS_NO_KICKBACKFEE_POPUP_CLOSED',
};

export function set(key, value) {
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    window.sessionStorage.setItem(key, value);
  }
}

export function remove(key) {
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    window.sessionStorage.removeItem(key);
  }
}

export function get(key) {
  const isBrowser = typeof window !== 'undefined';
  if (!isBrowser) {
    return null;
  }

  try {
    const item = window.sessionStorage.getItem(key);
    return item;
  } catch {
    return null;
  }
}
