import 'styles/reset.scss';
import 'styles/globals.scss';

import { UIContextProvider } from 'contexts/UIContext';
import { useEffect } from 'react';
import { SettingsContextProvider } from 'contexts/SettingContext';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { initGTM, initKlaviyo } from 'lib/utils/helpers';
import DatatricsAddToCart from 'components/shared/datatrics-add-to-cart/datatricsAddToCart';
import { useDidMountEffect } from '../hooks';

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    const isProd = window.location.hostname === process.env.NEXT_PUBLIC_SITE_HOSTNAME;
    if (!window.gtmDidInit && isProd) {
      initGTM();
    }
    initKlaviyo(router.locale);
    // initSqueezely();
  }, []);

  // Used for page-view tracking
  useDidMountEffect(() => {
    if (typeof window !== 'undefined' && window.dataLayer && !router.asPath.includes('salon/')) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ page: null }); // Clear the previous page object.
      window.dataLayer.push({
        event: 'route-change',
        page: {
          page_title: document.title,
          page_location: `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`,
          page_path: router.asPath,
        },
      });
    }
  }, [router.asPath]);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.dataLayer && !router.asPath.includes('salon/')) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ page: null }); // Clear the previous page object.
      window.dataLayer.push({
        event: 'route-change',
        page: {
          page_title: document.title,
          page_location: `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`,
          page_path: router.asPath,
        },
      });
    }
  }, []);

  // useEffect(() => {
  //   if (router.isReady) {
  //     window._sqzl = window._sqzl || [];
  //     window._sqzl.push({ event: 'PageView' });
  //     window._sqzl.push({ event: 'ReloadPersonalization' });
  //   }
  // }, [router]);

  return (
    <UIContextProvider>
      <SettingsContextProvider>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
          />
        </Head>
        <DatatricsAddToCart globalModules={pageProps?.globalModules} locale={pageProps?.locale} />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...pageProps} />
      </SettingsContextProvider>
    </UIContextProvider>
  );
}

export default MyApp;
